<template>
    <div class="order-detail-page">
        <!-- INSTORE ORDERS HEADING -->
        <request-pickup-modal :open="selectedOrder ? Boolean(!selectedOrder.external_delivery_id) : false" :onClose="() => selectedOrder = null"
            :order="selectedOrder" />

        <request-cancel-modal :open="selectedOrder ? Boolean(selectedOrder.external_delivery_id) : false" :onClose="() => selectedOrder = null"
            :order="selectedOrder" />

        <div class="columns table-headings is-size-7">
            <span class="driver-line" style="background-color: white;"></span>
            <div class="column is-1 name-column has-text-left move-text-up">NAME</div>
            <div class="column is-1 order-number-column has-text-centered move-text-up">SOURCE</div>
            <div class="column is-1 order-number-column has-text-centered move-text-up">#</div>
            <div class="column address-column has-text-left move-text-up">ADDRESS</div>
            <div class="column time-column has-text-centered">
                <div class="tooltip" v-show="tooltips[3]">
                    Time until ready
                    <div class="tooltip-point"></div>
                </div>
                <a class="button is-small has-text-weight-medium" v-on:click="showTool(3)">TUR</a>
            </div>
            <div class="column time-column has-text-centered" v-if="!retailOptimization">
                <div class="tooltip" v-show="tooltips[0]">
                    Time left till delivery
                    <div class="tooltip-point"></div>
                </div>
                <a class="button is-small has-text-weight-medium" v-on:click="showTool(0)">TLTD</a>
            </div>
            <div class="column time-column has-text-centered">
                <div class="tooltip" v-show="tooltips[4]">
                Time on road
                <div class="tooltip-point"></div>
            </div>
            <a class="button is-small has-text-weight-medium" v-on:click="showTool(4)">TOR</a>
            </div>
            <div class="column time-column has-text-centered" v-if="!retailOptimization">
                <div class="tooltip" v-show="tooltips[1]">
                    Target time
                    <div class="tooltip-point"></div>
                </div>
                <a class="button is-small has-text-weight-medium" v-on:click="showTool(1)">TGT</a>
            </div>
            <div class="column time-column has-text-centered">
                <div class="tooltip" v-show="tooltips[2]">
                Estimated delivery time
                <div class="tooltip-point"></div>
            </div>
            <a class="button is-small has-text-weight-medium" v-on:click="showTool(2)">EDT</a>
            </div>
            <div class="column is-1 status-column has-text-centered">
                <a class="button is-small has-text-weight-medium" v-on:click="confirmResetPrepTime()">
                    PREP {{ averagePrepTime }}
                </a>
            </div>
            <div class="column is-1 driver-column has-text-centered move-text-up">DRIVER</div>
            <div class="column is-1 button-column has-text-centered"></div>
            <div class="column is-1 button-column has-text-centered"></div>
        </div>

        <div class="order-details-area-route">
            <div v-if="assignedOrders.length" class="table-rows-top-group">
                <div class="columns table-rows is-size-7" draggable="true" v-on:dragover.prevent v-for="o in assignedOrders" v-bind:key="o.order_id"
                    v-on:dragstart="$emit('dragstart', o, 0, $event)" v-on:drop="$emit('dragdrop', o.driver_id, 0)"
                    v-on:click="$emit('toggleOrderSelection', o)">

                    <span v-if="o.driver_id || o.estimated_driver_id" class="driver-line" :style="{ backgroundColor: getDriverColor(o.driver_id || o.estimated_driver_id) }"></span>
                    <span v-if="!o.driver_id && !o.estimated_driver_id" class="driver-line" style="background-color: white;"></span>

                    <div class="column is-1 name-column has-text-left text-bold"
                        :class="
                        selectedOrders.filter((s) => s == o.order_id).length === 1
                            ? 'has-background-link'
                            : ''">
                        {{ getShortName(o.customer_name).toUpperCase() }}
                    </div>
                    <div class="column is-1 order-number-column has-text-centered">
                        <img class="img source-logo" :src="getOrderSourceLogo(o.OrderSource)" />
                    </div>
                    <div class="column is-1 order-number-column has-text-centered">
                        {{ o.source_id  ? getShortOrderNumber(o.source_id ) : o.order_number }}
                    </div>
                    <div class="column address-column has-text-left">{{ (o.address).toUpperCase() }}</div>
                    <div v-if='o.ScheduleType == "SCHEDULED"'><i :style="{'color':'#FD7567', 'margin-left':'-10px','margin-top':'17px'}" class="fa fa-clock-o"></i></div>
                    <div class="column time-column has-text-centered"
                        :class="{ 'green-color has-text-weight-bold': o.is_ready_to_deliver }">
                        {{ o.is_ready_to_deliver ? 'R' : Math.max(averagePrepTime - elapsedTime(o.entry_time), 0) }}
                    </div>
                    <div class="column time-column has-text-centered" v-if="!retailOptimization">
                        {{ o.target_delivery_time_minutes - elapsedTime(o.entry_time) }}
                    </div>
                    <!-- TOR -->
                    <div class="column time-column has-text-centered">
                        {{  o.time_on_road && o.time_on_road != -1 ? o.time_on_road : "--" }}
                    </div>
                    <div class="column time-column has-text-centered" v-if="!retailOptimization">
                        {{ o.target_delivery_time_minutes }}
                    </div>
                    <div class="column time-column has-text-weight-bold has-text-centered" :class="
                        o.target_delivery_time_minutes + 10 <= -stringTimeDiff(o.entry_time, o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver)
                            ?
                            ' will-be-late has-text-weight-bold '
                            :
                            o.target_delivery_time_minutes <= -stringTimeDiff(o.entry_time, o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver)
                                ?
                                'orange-color has-text-weight-bold'
                                :
                                'green-color has-text-weight-bold'
                    ">
                        {{ o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver ? - stringTimeDiff(o.entry_time, o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver) : '--'  }}
                    </div>
                    <div class="column is-1 status-column has-text-centered">
                        <a class="button is-small is-primary ready-button" v-bind:class="[
                            { 'is-outlined': !o.is_ready_to_deliver },
                            {
                                'is-loading': orderStatusChanging.indexOf(o.order_id) != -1,
                            },
                            {
                                'is-inverted':
                                    !o.is_ready_to_deliver &&
                                    selectedOrders.filter((s) => s == o.order_id).length === 1,
                            },
                        ]" v-on:click.stop="$emit('changeOrderStatus', o)">{{
                            o.is_ready_to_deliver ? "Ready" : "Not Ready"
                        }}</a>
                    </div>
                    <div class="column is-1 driver-column has-text-centered">
                        {{ o.driver_id ? driverShortName(o.driver_id) :
                            o.estimated_driver_id ? driverShortName(o.estimated_driver_id) :
                            o.external_delivery_source ? 'PENDING' : '--' }}
                    </div>
                    
                    <div v-if="!o.external_delivery_id" class="column is-1 button-column has-text-centered">
                        <a class="button is-outlined is-info is-small" v-on:click.stop="$emit('moveOrderUp', o)">
                            <span class="fa fa-arrow-up"></span>
                        </a>
                    </div>
                    <div v-if="!o.external_delivery_id" class="column is-1 button-column has-text-centered">
                        <a class="button is-outlined is-info is-small" v-on:click.stop="$emit('moveOrderDown', o)">
                            <span class="fa fa-arrow-down"></span>
                        </a>
                    </div>

                    <div v-if="o.external_delivery_id" class="column is-1 button-column has-text-centered">
                        <a class="button is-small is-info cancel-btn"
                            v-on:click.stop="() => toggleRequestModal(o)">
                            <i class="fa fa-close"></i>
                         </a>
                    </div>

                </div>
            </div>

            <div class="no-assigned" v-if="assignedOrders.length == 0 && onDutyDrivers[0] && getDrivers.length && isOpen" v-on:dragover.prevent
                v-on:drop="$emit('dragdrop', onDutyDrivers[0].driver_id, 0)">
                Drop orders here to assign to next driver
                <span v-if="onDutyDrivers[0]">({{ onDutyDrivers[0].driver_name + (onDutyDrivers[0].DriverLastName ? ` ${onDutyDrivers[0].DriverLastName}` : "" ) }})</span>
            </div>

            <div class="no-assigned" v-if="assignedOrders.length == 0 && !onDutyDrivers[0] && isOpen">
                Next driver not available
            </div>

            <div class="no-assigned" v-if="!isOpen">
                Store is still closed, open to drag orders
            </div>

            <!-- Assigned Orders -->
            <div class="columns table-headings is-size-7">
                <span class="driver-line" style="background-color: white;"></span>
                <div class="column is-1 name-column has-text-left">NAME</div>
                <div class="column is-1 order-number-column has-text-centered">SOURCE</div>
                <div class="column is-1 order-number-column has-text-centered">#</div>
                <div class="column address-column has-text-left">ADDRESS</div>
                <div class="column time-column has-text-centered">TUR</div>
                <div class="column time-column has-text-centered" v-if="!retailOptimization">TLTD</div>
                <div class="column time-column has-text-centered">TOR</div>
                <div class="column time-column has-text-centered" v-if="!retailOptimization">TGT</div>
                <div class="column time-column has-text-centered">EDT</div>
                <div class="column is-1 status-column has-text-centered"></div>
                <div class="column is-1 driver-column has-text-centered">DRIVER</div>
                <div class="column is-1 status-column has-text-centered"></div>
            </div>

            <!-- nextRouteOrders -->
            <div v-if="nextRouteOrders.length">
                <div class="columns table-rows is-size-7" draggable="true" v-on:dragover.prevent v-for="o in nextRouteOrders" v-bind:key="o.order_id"
                    v-on:drop="$emit('dragdrop', null, 1)" v-on:dragstart="$emit('dragstart', o, 1, $event)"
                    v-on:click="$emit('toggleOrderSelection', o)">

                    <span v-if="o.driver_id || o.estimated_driver_id" class="driver-line" :style="{ backgroundColor: getDriverColor(o.driver_id || o.estimated_driver_id) }"></span>
                    <span v-if="!o.driver_id && !o.estimated_driver_id" class="driver-line" style="background-color: white;"></span>

                    <div class="column is-1 name-column has-text-left text-bold"
                        :class="
                        selectedOrders.filter((s) => s == o.order_id).length === 1
                            ? 'has-background-link'
                            : ''">
                        {{ getShortName(o.customer_name).toUpperCase() }}
                    </div>
                    <div class="column is-1 order-number-column has-text-centered">
                        <img class="img source-logo" :src="getOrderSourceLogo(o.OrderSource)" />
                    </div>
                    <div class="column is-1 order-number-column has-text-centered">
                        {{ o.source_id  ? getShortOrderNumber(o.source_id ) : o.order_number }}
                    </div>
                    <div class="column address-column has-text-left">{{ (o.address).toUpperCase() }}</div>
                    <div v-if='o.ScheduleType == "SCHEDULED"'><i :style="{'color':'#FD7567', 'margin-left':'-10px','margin-top':'17px'}" class="fa fa-clock-o"></i></div>
                    <div class="column time-column has-text-centered"
                        :class="{ 'green-color has-text-weight-bold': o.is_ready_to_deliver }">
                        {{ o.is_ready_to_deliver ? 'R' : Math.max(averagePrepTime - elapsedTime(o.entry_time), 0) }}
                    </div>
                    <div class="column time-column has-text-centered" v-if="!retailOptimization">
                        {{ o.target_delivery_time_minutes - elapsedTime(o.entry_time) }}
                    </div>
                    <!-- TOR -->
                    <div class="column time-column has-text-centered">
                        {{  o.time_on_road && o.time_on_road != -1 ? o.time_on_road : "--" }}
                    </div>
                    <div class="column time-column has-text-centered" v-if="!retailOptimization">
                        {{ o.target_delivery_time_minutes }}
                    </div>
                    <div class="column time-column has-text-weight-bold has-text-centered" :class="
                        o.target_delivery_time_minutes + 10 <= -stringTimeDiff(o.entry_time, o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver)
                            ?
                            ' will-be-late has-text-weight-bold '
                            :
                            o.target_delivery_time_minutes <= -stringTimeDiff(o.entry_time, o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver)
                                ?
                                'orange-color has-text-weight-bold'
                                :
                                'green-color has-text-weight-bold'
                    ">
                        {{ o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver ? - stringTimeDiff(o.entry_time, o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver) : '--'  }}
                    </div>
                    <div class="column is-1 status-column has-text-centered">
                        <a class="button is-small is-primary ready-button" v-bind:class="[
                            { 'is-outlined': !o.is_ready_to_deliver },
                            {
                                'is-loading': orderStatusChanging.indexOf(o.order_id) != -1,
                            },
                            {
                                'is-inverted':
                                    !o.is_ready_to_deliver &&
                                    selectedOrders.filter((s) => s == o.order_id).length === 1,
                            },
                        ]" v-on:click.stop="$emit('changeOrderStatus', o)">{{
                            o.is_ready_to_deliver ? "Ready" : "Not Ready"
                        }}</a>
                    </div>
                    <div class="column is-1 driver-column has-text-centered">
                        {{ o.driver_id ? driverShortName(o.driver_id) :
                            o.estimated_driver_id ? driverShortName(o.estimated_driver_id) :
                            o.external_delivery_source ? 'PENDING' : '--' }}
                    </div>           
                    <div class="column is-1 button-column has-text-centered">
                        <a v-if="!o.external_delivery_id"   class="button is-small is-info"
                            :disabled="o.payment != 'Prepaid' || !quoteEnabled"
                            v-on:click.stop="() => toggleRequestModal(o)">
                            <i class="fa fa-sign-out"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="columns table-divider"></div>

            <!-- inStoreNotNextOrders -->
            <div v-if="inStoreNotNextOrders.length">
                <div class="columns table-rows is-size-7" draggable="true" v-on:dragover.prevent v-for="o in inStoreNotNextOrders" v-bind:key="o.order_id"
                    v-on:drop="$emit('dragdrop', null, 1)" v-on:dragstart="$emit('dragstart', o, 2, $event)"
                    v-on:click="$emit('toggleOrderSelection', o)">

                    <span v-if="o.driver_id || o.estimated_driver_id" class="driver-line" :style="{ backgroundColor: getDriverColor(o.driver_id || o.estimated_driver_id) }"></span>
                    <span v-if="!o.driver_id && !o.estimated_driver_id" class="driver-line" style="background-color: white;"></span>

                    <div class="column is-1 name-column has-text-left text-bold"
                        :class="
                        selectedOrders.filter((s) => s == o.order_id).length === 1
                            ? 'has-background-link'
                            : ''">
                        {{ getShortName(o.customer_name).toUpperCase() }}
                    </div>
                    <div class="column is-1 order-number-column has-text-centered">
                        <img class="img source-logo" :src="getOrderSourceLogo(o.OrderSource)" />
                    </div>
                    <div class="column is-1 order-number-column has-text-centered">
                        {{ o.source_id  ? getShortOrderNumber(o.source_id ) : o.order_number }}
                    </div>
                    <div class="column address-column has-text-left">{{ (o.address).toUpperCase() }}</div>
                    <div v-if='o.ScheduleType == "SCHEDULED"'><i :style="{'color':'#FD7567', 'margin-left':'-10px','margin-top':'17px'}" class="fa fa-clock-o"></i></div>
                    <div class="column time-column has-text-centered"
                        :class="{ 'green-color has-text-weight-bold': o.is_ready_to_deliver }">
                        {{ o.is_ready_to_deliver ? 'R' : Math.max(averagePrepTime - elapsedTime(o.entry_time), 0) }}
                    </div>
                    <div class="column time-column has-text-centered" v-if="!retailOptimization">
                        {{ o.target_delivery_time_minutes - elapsedTime(o.entry_time) }}
                    </div>
                    <!-- TOR -->
                    <div class="column time-column has-text-centered">
                        {{  o.time_on_road && o.time_on_road != -1 ? o.time_on_road : "--" }}
                    </div>
                    <div class="column time-column has-text-centered" v-if="!retailOptimization">
                        {{ o.target_delivery_time_minutes }}
                    </div>
                    <div class="column time-column has-text-weight-bold has-text-centered" :class="
                        o.target_delivery_time_minutes + 10 <= -stringTimeDiff(o.entry_time, o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver)
                            ?
                            ' will-be-late has-text-weight-bold '
                            :
                            o.target_delivery_time_minutes <= -stringTimeDiff(o.entry_time, o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver)
                                ?
                                'orange-color has-text-weight-bold'
                                :
                                'green-color has-text-weight-bold'
                    ">
                        {{ o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver ? - stringTimeDiff(o.entry_time, o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver) : '--'  }}
                    </div>
                    <div class="column is-1 status-column has-text-centered">
                        <a class="button is-small is-primary ready-button" v-bind:class="[
                            { 'is-outlined': !o.is_ready_to_deliver },
                            {
                                'is-loading': orderStatusChanging.indexOf(o.order_id) != -1,
                            },
                            {
                                'is-inverted':
                                    !o.is_ready_to_deliver &&
                                    selectedOrders.filter((s) => s == o.order_id).length === 1,
                            },
                        ]" v-on:click.stop="$emit('changeOrderStatus', o)">{{
                            o.is_ready_to_deliver ? "Ready" : "Not Ready"
                        }}</a>
                    </div>
                    <div class="column is-1 driver-column has-text-centered">
                        {{ o.driver_id ? driverShortName(o.driver_id) :
                            o.estimated_driver_id ? driverShortName(o.estimated_driver_id) :
                            o.external_delivery_source ? 'PENDING' : '--' }}
                    </div>
                    <div class="column is-1 status-column has-text-centered">
                        <a v-if="!o.external_delivery_id" class="button is-small is-info"
                            :disabled="o.payment != 'Prepaid' || !quoteEnabled"
                            v-on:click.stop="() => toggleRequestModal(o)">
                            <i class="fa fa-sign-out"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");
var requestPickupModal = require("../../components/add_edit_order/request_pickup_modal").default;
var requestCancelModal = require("../../components/add_edit_order/request_cancel_modal").default;

module.exports = {
    components: {
        requestPickupModal,
        requestCancelModal,
    },
    data: function () {
        return {
            selectedOrder: null,
            tooltips: [false,false,false,false,false]
        };
    },
    created: function () { },
    computed: {
        retailOptimization: function () {
            return this.$store.getters["orders/getStore"]['retailOptimization']
        },
        averagePrepTime: function () {
            return this.$store.getters["orders/averagePrepTime"];
        },
        getDrivers: function () {
            return this.$store.getters["drivers/getDrivers"];
        },
        onDutyDrivers: function () {
            return this.$store.getters["drivers/onDutyDrivers"];
        },
        selectedOrders: function () {
            return this.$store.getters["orders/selectedOrders"];
        },
        assignedOrders: function () {
            return (this.$store.getters["orders/assignedOrders"] || []).filter(o => o.OrderType != 'Pickup');
        },
        inStoreNotNextOrders: function () {
            return (this.$store.getters["orders/inStoreNotNext"] || []).filter(o => o.OrderType != 'Pickup');
        },
        nextRouteOrders: function () {
            return (this.$store.getters["orders/nextRoute"] || []).filter(o => o.OrderType != 'Pickup');
        },
        // hold all order statuses that are changing.
        orderStatusChanging: function () {
            return this.$store.getters["orders/orderStatusChanging"];
        },
        isOpen: function () {
            return this.$store.getters["orders/getStore"].isOpen;
        },
        quoteEnabled: function () {
            return this.$store.state.settings["DeliveryQuoteEnabled"];
        },
        defaultOrderPrepTime: function () {
            return this.$store.getters["orders/getDefaultOrderPrepTime"]
        },
    },
    watch: {},
    methods: {
        confirmResetPrepTime() {
            this.$confirm(`Are you sure you want to reset your prep time to the default time of ${this.defaultOrderPrepTime} minutes?`).then(() => {
                this.resetPrepTime();
            });
        },
        resetPrepTime() {
            this.$http
                .post(this.api_url + "Api/V1/Orders/IgnoreStoreOrdersPrepTime/" + this.store_id)
                .then(
                    () => {
                        console.log("Prep Time Ignored")
                    },
                    (err) => {
                        console.err(err)
                    }
                );
        },
        getDriverColor(driverId){
            console.log("fdsf", this.retailOptimization)
            const driver = this.getDrivers.find(d => d.driver_id === driverId);
            if(driver){
                const driverFullName = driver.driver_name + " " + driver.DriverLastName;
                const hashCode = this.hashCode(driverFullName);
                const color = this.intToRGB(hashCode);
                return '#' + color;
            }
        },
        hashCode(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            return hash;
        },
        intToRGB(i) {
            const c = (i & 0x00FFFFFF)
                .toString(16)
                .toUpperCase();
            return "00000".substring(0, 6 - c.length) + c;
        },
        driverShortName(driverId) {
            const matchedDriver = this.getDrivers.find(d => d.driver_id === driverId);
            return matchedDriver ? this.getDriverShortName(matchedDriver.driver_name, matchedDriver.DriverLastName, 7).toUpperCase() : "";
        },
        showTool(a) {
            for(var i = 0; i < this.tooltips.length; i++){
                this.$set(this.tooltips, i, false);
            }
            this.$set(this.tooltips, a, true);
            setTimeout(() => this.$set(this.tooltips, a, false), 5000)
        },
        elapsedTime: (a) => timeFunctions.elapsedTime(a),
        stringTimeDiff: (a, b) => timeFunctions.stringTimeDiff(a, b),
        shortAddress: (address) => orderFunctions.getShortAddress(address),
        getShortOrderNumber: (orderNumber) => orderFunctions.getShortOrderNumber(orderNumber),
        toggleRequestModal: function (order) {
            this.selectedOrder = order;
        },
        getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
        getShortName: (name) => orderFunctions.getShortName(name),
        getDriverShortName: (fname, lname, limitFirstName) => orderFunctions.getDriverShortName(fname, lname, limitFirstName),
        currentTime: () => timeFunctions.currentTime(),
        convertToTime: (a) => timeFunctions.convertToTime(a),
    },
    filters: {},
};

</script>

<style scoped>
.driver-line {
    width: 6px;
}

.table-rows-top-group {
    padding-bottom: 1rem;
}

.source-logo {
    width: 1.4rem;
}

.table-divider{
    border-bottom-width: 6px;
}
.no-assigned{
    margin-top: 5px;
    margin-bottom: 5px;
}

.ready-button{
    width: 72px !important;
    flex-shrink: 0 !important;
}
</style>
