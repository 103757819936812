<template>
  <div>
    <article
      class="panel"
      :class="this.selected ? 'is-link' : ''"
      @click="$emit('selectDriver')"
    >
      <div class="panel-heading has-text-weight-medium" :class="this.selected ? 'invert-border' : ''">
        <!-- DRIVER HEADER -->
        <div class="columns is-vcentered driver-heading">
          <div 
            class="column is-uppercase driver-name"
          >
            {{ getDriverShortName(driver.driver_name, driver.DriverLastName, 7) }}
          </div>

          <div v-if="expandLevel != 1" class="center-heading columns column has-text-centered">
            <div v-if=" driver.time_driver_left != null" class="column has-text-centered driver-header-info-text">
              <div v-if="driver.time_driver_left != null">OUT</div>
              <div v-if="driver.time_driver_left != null">
                {{ elapsedTime(driver.time_driver_left) }}
              </div>
            </div>
            <div v-if="driver.route != '' && driver.time_driver_left != null && shouldShowTravelTime" class="column has-text-centered driver-header-info-text">
              <div>BACK</div>
              <div>
                {{ driver.time_til_back_to_shop }}
              </div>
            </div>
            <div v-if="driver.route != '' && (driver.time_driver_left == null || expandLevel == 3) && shouldShowTravelTime" class="column has-text-centered driver-header-info-text">
              <div>
                TRAVEL
              </div>
              <div>
                {{ driver.orders_travel_time }}
              </div>
            </div>
            <div v-if="(driver.route == '' || expandLevel == 3)" class="column has-text-centered driver-header-info-text">
              <div>ORDERS</div>
              <div>{{ this.orderCount }}</div>
            </div>
          </div>

          <div 
            class="column button-group" v-if="(expandLevel == 3 || expandLevel == 2)"
          >
            <!-- <a
              class="
                button
                is-small 
                is-pulled-right 
                is-header-button
                driver-panel-icon
              "
              v-on:click.stop="clearSelections"
              v-if="!(driver.time_driver_left == null && driver.route != '')"
              ><i class="fa fa-angle-up fa-3x clear-icon"></i>
            </a> -->
            <!-- <a
              class="
                button
                is-small 
                is-pulled-right 
                is-header-button
                driver-panel-icon
              "
              v-on:click.stop="$emit('optimizeRoute')"
              v-if="driver.time_driver_left == null && driver.route != '' && canOptimze"
              ><span class="fa fa-bolt fa-3x optimize-icon"></span>
            </a> -->
          </div>
        </div>

        <!-- <div class="columns is-vcentered" v-if="isCollapsed && (assignedRoute.length || tentativeRoute.length)">
            <div class="column has-text-centered collapse-button-top">
              <div>
                <a
                  class="
                    button
                    is-small
                    is-header-button
                    big-button-header
                    is-link
                    driver-collapse-arrow
                    collapse-button-margin
                  "
                  :class="{ 'white-icon': isCollapsed && selected }"
                  @click.stop="$emit('removeRoute')"
                >
                  <i class="fa fa-angle-down fa-2x" v-if="!isCollapsed"></i>
                </a>
              </div>
            </div>
        </div> -->
      </div>

      <div v-if="expandLevel !== 1" v-bind:class="{ 'is-hidden':  !(assignedRoute.length || tentativeRoute.length) }">
        <!-- ORDERS HEADER -->
        <div v-if="(expandLevel == 3)" class="columns order-heading table-rows is-size-7 has-text-centered">
          <div class="column has-text-left" :class="expandLevel == 3 ? 'is-3' : expandLevel == 1 ? 'is-6' : 'is-3'">NAME</div>
          <div class="column is-1 has-text-centered no-padding">SRC</div>
          <div class="column has-text-left" :class="expandLevel == 3 ? 'is-5' : 'is-4'">ADDRESS</div>
          <div class="column has-text-centered no-padding">{{ driver.time_driver_left ? 'TUD' : 'TOR'}}</div>
          <!-- <div class="column has-text-centered no-padding">
            TGT
          </div> -->
          <div class="column has-text-centered no-padding">EDT</div>
          <div class="column has-text-centered no-padding" v-if="driver.time_driver_left != null && driver.route != ''">ADT</div>
           
        </div>
        <div v-if="(expandLevel == 2)" class="columns order-heading table-rows is-size-7 has-text-centered">
          <div class="column is-3 has-text-left">NAME</div>
          <div class="column is-1 has-text-centered no-padding">SRC</div>
          <!-- <div class="column has-text-centered">
            TGT
          </div> -->
          <div class="column has-text-centered">EDT</div>
        </div>
        <div v-if="(expandLevel == 1)" class="columns order-heading table-rows is-size-7 has-text-centered">
          <div class="column is-6 has-text-left">NAME</div>
          <div class="column is-6 has-text-centered">EDT</div>
        </div>

        <!-- ASSIGNED ORDERS DETAILS -->
        <div
          class="columns orders assigned-orders table-rows is-size-6 has-text-centered"
          v-for="assignedOrder in assignedRoute"
          v-bind:key="assignedOrder.order_id"
          v-on:click.stop="$emit('toggleOrderSelection', assignedOrder)"
        >
          <div class="column is-uppercase is-size-10 has-text-left text-ellipsis assigned-order-info-text text-bold"
              :class="{
                'has-background-link': selectedOrders.filter((s) => s === assignedOrder.order_id).length === 1,
                'is-6': expandLevel === 1,
                'is-3': expandLevel !== 1 || expandLevel !== 3
              }">
            {{ getShortName(assignedOrder.customer_name, 6).toUpperCase() }}
          </div>
          <div class="column is-1  has-text-centered no-padding">
            <img class="img source-logo" 
              :src="getOrderSourceLogo(assignedOrder.OrderSource)" 
            />
          </div>
          <div v-if="(expandLevel == 3)"  class="column is-uppercase has-text-left text-ellipsis assigned-order-info-text"
          :class="expandLevel == 3 ? 'is-5' : 'is-4'">
            {{ shortAddress(assignedOrder.address) }}
          </div>
          <!-- TOR/TUD -->
          <div v-if="(expandLevel == 3)" class="column assigned-order-info-text"
          :class="assignedOrder.delivery_time ? 'delivered-item' : assignedOrder.is_return ? 'returned-item' : ''">
            {{ assignedOrder.delivery_time ? 'D' : assignedOrder.is_return ? 'R' : 
              driver.time_driver_left && assignedOrder.estimated_delivery_time ? -elapsedTime(assignedOrder.estimated_delivery_time) : assignedOrder.time_on_road != -1 ? assignedOrder.time_on_road : "--" }} 
          </div>
          <!-- tgt-->
          <!-- <div v-if="(expandLevel == 3 || expandLevel == 2)" class="column assigned-order-info-text"
          >
            {{ assignedOrder.target_delivery_time_minutes }}
          </div> -->
          <!-- edt -->
          <div
              class="column  assigned-order-info-text has-text-centered"
              :class="assignedOrder.target_delivery_time_minutes + 10 <= -stringTimeDiff(
                  assignedOrder.entry_time,
                  assignedOrder.estimated_delivery_time
                )
                  ?
                  ' will-be-late has-text-weight-bold '
                  : assignedOrder.target_delivery_time_minutes <= -stringTimeDiff(
                      assignedOrder.entry_time,
                      assignedOrder.estimated_delivery_time
                    )
                      ? 'orange-color has-text-weight-bold '
                      : assignedOrder.target_delivery_time_minutes > -stringTimeDiff(
                      assignedOrder.entry_time,
                      assignedOrder.estimated_delivery_time
                    )
                  ? 'green-color has-text-weight-bold ' : ''
                "
            >
              {{ assignedOrder.delivery_time && assignedOrder.expected_time_minutes ? assignedOrder.expected_time_minutes : 
                assignedOrder.estimated_delivery_time ?
                -stringTimeDiff(
                  assignedOrder.entry_time,
                  assignedOrder.estimated_delivery_time
                ) : "--"
              }}
            </div>

            <div v-if="(expandLevel == 3) && driver.time_driver_left != null && driver.route != ''"
            class="column assigned-order-info-text has-text-centered"
              :class="
                assignedOrder.target_delivery_time_minutes + 10 <
                  -stringTimeDiff(assignedOrder.entry_time, assignedOrder.delivery_time)
                  ? ' will-be-late has-text-weight-bold'
                  : assignedOrder.target_delivery_time_minutes <
                    -stringTimeDiff(assignedOrder.entry_time, assignedOrder.delivery_time)
                  ? 'orange-color has-text-weight-bold'
                  : assignedOrder.target_delivery_time_minutes >
                    -stringTimeDiff(assignedOrder.entry_time, assignedOrder.delivery_time)
                  ? 'green-color has-text-weight-bold' : ''
              "
            >
              {{
                assignedOrder.delivery_time
                  ? -stringTimeDiff(assignedOrder.entry_time, assignedOrder.delivery_time)
                  : "--"
              }}
            </div>
        </div>

        <!-- ASSIGNED ORDERS HEADER -->
        <div
          class="columns orders order-heading assigned-order-heading is-size-7 has-text-centered"
          v-if="
            assignedRoute.length &&
            !(driver.time_driver_left != null && driver.route != '')
          "
        >
          <div class="column is-3 has-text-centered">
            <a
              class="is-small driver-panel-icon assigned-footer-icon"
              v-on:click.stop="$emit('optimizeRoute')"
              v-if="
                driver.time_driver_left == null && driver.route != '' && canOptimze
              "
              ><span class="fa fa-bolt fa-2x optimize-icon"></span>
            </a>
          </div>
          <div
            class="column is-6 has-text-centered assigned-route"
          >
            ASSIGNED
          </div>
          <div class="column is-3 has-text-centered assigned-footer-icon">
            <a
              class="is-small driver-panel-icon"
              v-on:click.stop="$emit('removeRoute')"
              ><i class="fa fa-undo fa-2x remove-route-icon-color"></i>
            </a>
          </div>
        </div>

        <div
          class="columns orders order-heading assigned-order-heading is-size-7 has-text-centered"
          v-if="assignedRoute.length && driver.time_driver_left != null && driver.route != ''"
        >
          <div class="column is-3 has-text-centered">
          </div>
          <div
            class="column is-6 has-text-centered assigned-route"
          >
          ON THE ROAD
          </div>
          <div class="column is-3 has-text-centered assigned-footer-icon">
            <a
              class="is-small driver-panel-icon"
              v-on:click.stop="$emit('removeRoute')"
              ><i class="fa fa-undo fa-2x remove-route-icon-color"></i>
            </a>
          </div>
        </div>

        <!-- TENTATIVE ORDERS DETAILS -->
        <div
          class="columns orders tentative-orders table-rows is-size-6 has-text-centered"
          v-for="tentativeOrder in tentativeRoute"
          v-bind:key="tentativeOrder.order_id"
          v-on:click.stop="$emit('toggleOrderSelection', tentativeOrder)"
        >
        <div class="column is-uppercase is-size-10 has-text-left text-ellipsis assigned-order-info-text text-bold"
            :class="{
              'has-background-link': selectedOrders.filter((s) => s === tentativeOrder.order_id).length === 1,
              'is-6': expandLevel === 1,
              'is-3': expandLevel !== 1 || expandLevel !== 3
            }">
            {{ getShortName(tentativeOrder.customer_name, 6).toUpperCase() }}
          </div>
        <div class="column is-1  has-text-centered no-padding">
            <img class="img source-logo" 
              :src="getOrderSourceLogo(tentativeOrder.OrderSource)" 
            />
          </div>
          <div v-if="(expandLevel == 3)"  class="column is-uppercase has-text-left text-ellipsis tentative-order-info-text"
          :class="expandLevel == 3 ? 'is-5' : 'is-4'">
            {{ shortAddress(tentativeOrder.address) }}
          </div>
            <!-- TOR -->
          <div v-if="(expandLevel == 3)" class="column tentative-order-info-text" 
            :class="tentativeOrder.delivery_time ? 'delivered-item' : tentativeOrder.is_return ? 'returned-item' : ''">
            {{ tentativeOrder.delivery_time ? 'D' : tentativeOrder.is_return ? 'R': driver.time_driver_left && tentativeOrder.estimated_delivery_time ? 
              -elapsedTime(tentativeOrder.estimated_delivery_time) :  tentativeOrder.time_on_road && tentativeOrder.time_on_road != -1 ? tentativeOrder.time_on_road : "--" }} 
          </div>
          <!-- tgt -->
          <!-- <div v-if="(expandLevel == 3 || expandLevel == 2)" class="column tentative-order-info-text">
            {{ tentativeOrder.target_delivery_time_minutes
            }}
          </div> -->
          <div
            class="column tentative-order-info-text "
            :class="
              tentativeOrder.target_delivery_time_minutes + 10 <= -stringTimeDiff(tentativeOrder.entry_time, tentativeOrder.estimated_delivery_time) 
              ? 
              ' will-be-late has-text-weight-bold ' 
              : tentativeOrder.target_delivery_time_minutes <= -stringTimeDiff(tentativeOrder.entry_time, tentativeOrder.estimated_delivery_time) 
              ? 'orange-color has-text-weight-bold ' 
              : tentativeOrder.target_delivery_time_minutes > -stringTimeDiff(tentativeOrder.entry_time, tentativeOrder.estimated_delivery_time)
              ? 'green-color has-text-weight-bold ' : ''
            "
          >
            {{
              tentativeOrder.estimated_delivery_time ?
              -stringTimeDiff(
                tentativeOrder.entry_time,
                tentativeOrder.estimated_delivery_time
              ) : "--"
            }}
          </div>

          <div v-if="(expandLevel == 3) && driver.time_driver_left != null && driver.route != ''"
            class="column tentative-order-info-text has-text-centered"
              :class="
                tentativeOrder.target_delivery_time_minutes + 10 <
                  -stringTimeDiff(tentativeOrder.entry_time, tentativeOrder.delivery_time)
                  ? ' will-be-late has-text-weight-bold'
                  : tentativeOrder.target_delivery_time_minutes <
                    -stringTimeDiff(tentativeOrder.entry_time, tentativeOrder.delivery_time)
                  ? ' orange-color has-text-weight-bold'
                  : tentativeOrder.target_delivery_time_minutes >
                    -stringTimeDiff(tentativeOrder.entry_time, tentativeOrder.delivery_time)
                  ? ' green-color has-text-weight-bold' : ''
              "
            >
              {{
                tentativeOrder.delivery_time
                  ? -stringTimeDiff(tentativeOrder.entry_time, tentativeOrder.delivery_time)
                  : "-"
              }}
            </div>
        </div>
        <!-- TENTATIVE ORDERS HEADER -->
        <div
          class="
            columns
            order-heading
            tentative-order-heading
            is-size-7
            has-text-centered
          "
          v-if="tentativeRoute.length"
        >
          <div class="column is-12 has-text-centered">NEXT ROUTE</div>
        </div>

      </div>
    </article>
    
  </div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");

module.exports = {
  components: {},
  data: function () {
    return {
      isCollapsed: true,
    };
  },
  created: function () {},
  props: ["driver", "first", "last"],
  computed: {
    shouldShowTravelTime() { 
      return !(this.$store.getters["orders/getStore"].packageId === 1 && this.$store.getters["orders/getStore"].smsEnabled === false)
    },
    canOptimze: function() {
      return this.$store.getters["orders/getStore"].packageId != 1 || this.$store.getters["orders/getStore"]['retailOptimization'];
    },
    expandLevel: function() {
      return this.$store.getters["app/getExpandLevel"]
    },
    selectedOrders: function () {
      return this.$store.getters["orders/selectedOrders"];
    },
    tentativeRoute: function () {
      const tentativeRoute = this.$store.getters["orders/tentativeOrdersByDriverId"](
        this.driver.driver_id
      );
      return tentativeRoute
    },
    assignedRoute: function () {
      const assignedRoute = this.$store.getters["orders/assignedOrdersByDriverId"](
        this.driver.driver_id
      );
      return assignedRoute
    },
    orderCount: function () {
      return this.$store.getters["orders/orderCountByDriverId"](
        this.driver.driver_id
      );
    },
    selected: function () {
      return (
        this.$store.state.drivers.selected?.driver_id == this.driver.driver_id
      );
    },
    driverRoute: function () {
      return this.driver.route;
    },
    timeDriverLeft: function () {
      return this.driver.time_driver_left;
    },
    currentPanel: function () {
      return this.$store.getters["app/getCurrentPanel"];
    },
  },
  watch: {
    currentPanel(){
      if(this.currentPanel == "map"){
        this.isCollapsed = false;
      }
    },
    selected() {
      if (this.selected) {
        if (this.currentPanel == "dayPanel") {
          this.getorders();
        }
        // this.isCollapsed = false;
      }
    },
    timeDriverLeft() {
      this.isCollapsed = false;
    },
  },
  methods: {
    elapsedTime: (a) => timeFunctions.elapsedTime(a),
    currentTime: () => timeFunctions.currentTime(),
    convertToTime: (a) => timeFunctions.convertToTime(a),
    stringTimeDiff: (a, b) => timeFunctions.stringTimeDiff(a, b),
    shortAddress: (address) => orderFunctions.getShortAddress(address),
    getShortName: (name, limitFirstName) => orderFunctions.getShortName(name, limitFirstName),
    getDriverShortName: (fname, lname, limitFirstName) => orderFunctions.getDriverShortName(fname, lname, limitFirstName),
    getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
    clearSelections: function () {
      this.$store.dispatch("orders/clearSelectedOrders");
    },
    getCustomerNameFormatted: function(customerName){
      var splitCustName = customerName.split(" ");
      if (splitCustName.length > 1) {
        if (customerName.length > 10) {
          customerName = customerName.substring(0, 5);
        }
        customerName += " " + splitCustName[1].substring(0, 1);
      } else {
        if (customerName.length > 10) {
          customerName = customerName.substring(0, 5);
        }
      }
      return customerName;
    },
    // Get selected driver current day order
    getorders: function () {
      this.selectdDriver = this.$store.getters["drivers/selectedDriver"];
      var storeId = this.$store.getters["getStoreID"];
      par = {
        driverId: this.selectdDriver.driver_id,
        storeId: storeId,
        base_url: "",
      };
      this.$store.dispatch("orders/getCurrentDayOrders", par);
    },
  },
  filters: {},
};
</script>

<style scoped>
.remove-route-icon {
  color:#f14668;
  font-size: 2.5em;
}
.remove-route-icon-color {
  color:#f14668;
}
.dropdown-menu {
  display: block;
  position: relative;
}
.panel-heading {
  margin-bottom: 12px;
  padding: 0 !important;
  padding-bottom: 0 !important;
}
.columns {
  margin-left: 0px;
  margin-right: 0px;
}
.driver-heading{
  min-height: 40px;
  margin-bottom: 0 !important;
  font-size: 1rem !important;
  padding: .5em;
  margin: 0 !important;
}
.driver-heading .column {
  padding: 0px;
  margin-bottom: 0px !important;
}
.order-heading .column {
  padding: 0px;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-weight: bold;
  /* margin-top: .05em !important;
  margin-bottom: .05em !important; */
}
.orders .column {
  padding: 0px;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: .4em !important;
}
.orders .assigned-route{
  padding: 0px;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.2 !important;
}
.tentative-order-heading .column {
  background: white;
}
.assigned-footer-icon{
  padding-bottom: .3em !important;
}
.is-header-button {
  margin: 5px;
  background-color: transparent !important;
  background: transparent !important
}

.optimize-icon{
  color: #14f964;
}

.clear-icon {
  color: #f14668
}
.driver-panel-icon{
  background-color: transparent !important;
  padding: 6px 6px !important;
  width: 34px;
  height: 34px;
}

.button-group{
  margin: 0 !important;
}
.will-be-late {
  color: #f14668;
}
.delivered-item {
  color: #05A13C;
  font-weight: bold;
}
.returned-item {
  color: #f14668;
  font-weight: bold;
}
.big-button-header {
  margin-top: -12px;
  margin-bottom: -12px;
  font-size: 1.15rem !important;
  border-radius: 0px;
  width: 1em;
}
.text-ellipsis {
  width: 100px;
  overflow: hidden;
  display: inline-block;
  text-overflow: clip;
  white-space: nowrap;
}

.panel-heading{
  background-color: white;
  border: 2px solid #3273dc;
  border-radius: 6px 6px 6px 6px;
}

.button.is-small {
    border: none;
    background-color: transparent;
}

.driver-header-info-text{
  font-size: .8em;
  max-width: 75px;
  padding-bottom: 0.5em !important;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}

.order-info-text{
  font-size: .8em;
}

.tentative-order-info-text{
  font-size: .8em;
  
}

.assigned-order-info-text{
  font-size: .8em;
}

.driver-collapse-arrow{
  height: 1.5em;
  padding: 0 !important; 
  margin: 0 !important;
  color: #3273dc !important;
}

.button.is-info.is-hovered, .button.is-info:hover {
  background-color: transparent !important;
}

.no-padding{
  padding: 0 !important;
}

.is-selected-background{
  background: #77A5EE;
}

.assign-route-header{
  background: lightyellow;
}
.next-route{
  margin-top: 3em;
}
.on-the-road{
  background: rgb(157, 232, 157);
}
.assigned-route {
  background: #3273dc;
  color: #fff;
  font-size: 1.2em;
}

.is-light{
  background-color: #fff !important;
  padding: 6px 6px !important;
  width: 34px;
  height: 34px;
}

.center-heading{
  justify-content: center;
  margin: .1em;
  font-size: 1.1rem !important;
}

/* .is-link .driver-collapse-arrow{
  background-color: #fff !important;
  color: #3273dc !important;
} */

.white-icon{
  color: white !important;
}
.img {
  margin-top: -10px !important;
}

.collapse-button {
  padding: 0 !important;
}

.collapse-button-top {
  padding: 0 !important;
  padding-bottom: .5em !important;
}

.collapse-button-margin {
  margin-bottom: 0px !important;
}

.invert-border { 
  border-color: white !important;
}
</style>