<template>
<div class="order-detail-page">
    <!-- Pending Orders -->
    <div class="columns table-headings is-size-7">
        <div class="column is-1 name-column has-text-left move-text-up">NAME</div>
        <div class="column is-1 order-number-column has-text-left move-text-up">SOURCE</div>
        <div class="column is-1 order-number-column has-text-left move-text-up">#</div>
        <div class="column address-column has-text-left move-text-up">ADDRESS</div>
        <div class="column time-column has-text-centered" v-if="!retailOptimization">
            <div class="tooltip" v-show="tooltips[0]">
            Time left till delivery
            <div class="tooltip-point"></div>
          </div>
          <a class="button is-small has-text-weight-medium" v-on:click="showTool(0)">TLTD</a>
        </div>
        <div class="column time-column has-text-centered" v-if="!retailOptimization">
            <div class="tooltip" v-show="tooltips[1]">
            Target time
            <div class="tooltip-point"></div>
          </div>
          <a class="button is-small has-text-weight-medium" v-on:click="showTool(1)">TGT</a>
        </div>
        <div class="column time-column has-text-centered">
            <div class="tooltip" v-show="tooltips[2]">
            Elapsed time
            <div class="tooltip-point"></div>
          </div>
          <a class="button is-small has-text-weight-medium" v-on:click="showTool(2)">ELP</a>
        </div>
        <div class="column time-column has-text-centered" v-if="!retailOptimization">
            <div class="tooltip" v-show="tooltips[3]">
            Time until delivery
            <div class="tooltip-point"></div>
          </div>
          <a class="button is-small has-text-weight-medium" v-on:click="showTool(3)">TUD</a>
        </div>
        <div class="column time-column has-text-centered">
            <div class="tooltip" v-show="tooltips[4]">
            Estimated delivery time
            <div class="tooltip-point"></div>
          </div>
          <a class="button is-small has-text-weight-medium" v-on:click="showTool(4)">EDT</a>
        </div>
    </div>

    <div class="order-details-area" v-if="pendingOrders.length">
        <div class="columns table-rows is-size-7" v-for="o in pendingOrders" v-bind:key="o.order_id" v-on:click="$emit('toggleOrderSelection', o)">

            <div class="column is-1 name-column has-text-left text-bold"
                :class="
                  selectedOrders.filter((s) => s == o.order_id).length === 1
                    ? 'has-background-link'
                    : ''
                ">
                {{ getShortName(o.customer_name).toUpperCase() }}
            </div>
            <div class="column is-1 order-number-column has-text-left">
                <img class="img source-logo" :src="getOrderSourceLogo(o.OrderSource)" />
            </div>
            <div class="column is-1 order-number-column has-text-left">
                {{ o.source_id  ? getShortOrderNumber(o.source_id ) : o.order_number }}
            </div>
            <div class="column address-column has-text-left">{{ (o.address).toUpperCase() }}</div>
            <div class="column time-column has-text-centered"  v-if="!retailOptimization">
                {{ o.target_delivery_time_minutes - elapsedTime(o.entry_time) }}
            </div>
            <div class="column time-column has-text-centered"  v-if="!retailOptimization">
                {{ o.target_delivery_time_minutes }}
            </div>
            <div class="column time-column has-text-centered">
                {{ elapsedTime(o.entry_time) }}
            </div>
            <div class="column time-column has-text-centered"  v-if="!retailOptimization">
                {{ o.delivery_time ? 'D' : o.estimated_delivery_time ? -elapsedTime(o.estimated_delivery_time) : '--' }}
            </div>
            <div class="column time-column has-text-weight-bold has-text-centered" 
                :class="
                    o.target_delivery_time_minutes + 10 < -stringTimeDiff(o.entry_time, o.estimated_delivery_time)
                    ? 
                    ' will-be-late has-text-weight-bold '
                    :
                    o.target_delivery_time_minutes < -stringTimeDiff(o.entry_time, o.estimated_delivery_time) 
                    ? 
                    'orange-color has-text-weight-bold' 
                    : 
                    'green-color has-text-weight-bold' 
                "
            >
                {{ o.estimated_delivery_time ? -stringTimeDiff(o.entry_time, o.estimated_delivery_time) : '--' }}
            </div>
            <div class="column is-2 status-column has-text-centered">
                <a class="button is-small is-primary ready-button is-outlined" 
                    v-bind:class="[
                            {
                                'is-loading': orderStatusChanging.indexOf(o.order_id) != -1,
                            },
                        ]"
                        v-on:click.stop="toggleOrderPendingStatus(o)">
                   Send to Delivery
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");

module.exports = {
    components: {},
    data: function () {
        return {
            tooltips: [false,false,false,false,false],
            api_url: this.$store.state.api_url,
            store_id: this.$store.state.user.store_id,
        };
    },
    created: function () {},
    computed: {
        retailOptimization: function () {
            return this.$store.getters["orders/getStore"]['retailOptimization']
        },
        selectedOrders: function () {
            return this.$store.getters["orders/selectedOrders"];
        },
        pendingOrders: function () {
            return (this.$store.getters["orders/pendingOrders"] || []).filter(o => !o?.external_delivery_id);
        },
        orderStatusChanging: function () {
            return this.$store.getters["orders/orderStatusChanging"];
        },
    },
    watch: {},
    methods: {
        showTool(a) {
            for(var i = 0; i < this.tooltips.length; i++){
            this.$set(this.tooltips, i, false);
            }
            this.$set(this.tooltips, a, true);
            setTimeout(() => this.$set(this.tooltips, a, false), 5000)
        },
        elapsedTime: (a) => timeFunctions.elapsedTime(a),
        stringTimeDiff: (a, b) => timeFunctions.stringTimeDiff(a, b),
        shortAddress: (address) => orderFunctions.getShortAddress(address),
        getShortName: (name) => orderFunctions.getShortName(name),
        getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
        getShortOrderNumber: (orderNumber) => orderFunctions.getShortOrderNumber(orderNumber),
        toggleOrderPendingStatus: function (order) {
            this.$store.dispatch("orders/addOrderStatusChanging", order);
            const body = {
                storeId: this.store_id,
                orderId: order.order_id,
                pending: false
            }
            this.$http
                .post(this.api_url + "Api/V1/Orders/UpdateOrderPendingStatus/", body)
                .then(
                    () => {
                        console.log("Order sent to delivery")
                        this.$store.dispatch("orders/removeOrderStatusChanging", order);
                    },
                    (err) => {
                        this.$store.dispatch("orders/removeOrderStatusChanging", order);
                        console.log(err)
                    }
                );
        }
    },
    filters: {},
};
</script>

<style scoped>
.order-details-area{
    max-height: calc(100vh - 140px);
    overflow-y: auto;
}
.ready-button {
  width: 100px;
}
</style>
