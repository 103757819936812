<template>
  <div>
    <div class="card">
      <!------- start Table Header ----->
      <header class="card-header">
        <div class="is-grouped">
          <!-- Start Open close day buttons -->
          <a
            class="button is-primary header-btn has-text-weight-medium"
            v-on:click="!store.isOpen && storeDialpad()"
            :disabled="store.isOpen"
          >
            <i class="fa fa-money"></i>Open Day
          </a>

          <a
            class="button is-danger header-btn has-text-weight-medium"
            v-on:click="store.isOpen && checkForOpenPickupOrders()"
            :disabled="!store.isOpen"
          >
            <i class="fa fa-times-circle"></i> Close Day
          </a>

          <a
            v-if="showAccounts"
            class="button is-link header-btn has-text-weight-medium"
            v-on:click="openDriverAccountModal()"
            :disabled="!hasDriverAccounts"
          >
            Driver Accounts
          </a>

          <!-- end Open close day buttons -->
        </div>
      </header>
      <!------ end Table Header ----->

      <!-----start today order list table---- -->
      <div class="card-content">
        <!-- <----- Start table heading ----->
        <div style="margin-top: 2em" class="columns table-headings is-size-7">
          <div class="column is-1 has-text-left">SOURCE</div>
          <div class="column address-column is-1 has-text-left">NAME</div>
          <div class="column is-1 has-text-left">#</div>
          <div class="column is-1 has-text-left">PMT TYPE</div>
          <div class="column is-1 has-text-left">TOTAL</div>
          <div class="column is-1 has-text-left">DRIVER</div>
          <div class="column is-1 has-text-left">DRIVER FEE</div>
          <div class="column is-1 has-text-left">ACTUAL TIP</div>
        </div>
        <div
          class="order-details-area"
          :style="{
            'max-height': expandSummaryPanel
              ? 'calc(100vh - 474px)'
              : 'calc(100vh - 174px)',
          }"
        >
          <div
            class="columns table-today-orders-rows is-size-7"
            v-for="order in currentDayOrders"
            v-bind:key="order.orderId"
            v-on:click="toggleOrderSelection(order)"
          >
            <div class="column is-1 has-text-left">
              <img
                class="img source-logo"
                :src="getOrderSourceLogo(order.OrderSource)"
              />
            </div>
            <div class="column address-column is-1 has-text-left text-bold" 
                :class="selectedOrders.filter((s) => s == order.order_id).length === 1
                            ? 'has-background-link'
                            : ''">
              {{ getShortName(order.customer_name, 7).toUpperCase() }}
            </div>
            <div class="column is-1 has-text-left">
              {{
                order.source_id
                  ? getShortOrderNumber(order.source_id)
                  : order.order_number
              }}
            </div>
            <div class="column is-1 has-text-left">
              {{ order.payment.toUpperCase() }}
            </div>
            <div class="column is-1 has-text-left">
              {{ order.total | formatAmount }}
            </div>

            <div class="column is-1 has-text-left">
              {{
                order.driver_id
                  ? getDriverShortName(
                      (
                        drivers.find((d) => d.driver_id == order.driver_id) ||
                        {}
                      ).driver_name,
                      (
                        drivers.find((d) => d.driver_id == order.driver_id) ||
                        {}
                      ).DriverLastName
                    )
                  : order.external_delivery_source
                  ? order.external_delivery_source.toUpperCase()
                  : "--"
              }}
            </div>

            <div class="column is-1 has-text-left">
              {{ order.driverDeliveryFee | formatAmount }}
            </div>

            <div class="column is-1 has-text-left">
              <div v-if="order.payment != 'Cash'">
                <input
                  class="input is-small"
                  v-on:keypress="isValidNumber($event)"
                  min="0"
                  step="0.01"
                  max="999.99"
                  :value="order.tip"
                  v-on:blur="
                    checkSpecialKeys(
                      $event,
                      order.order_id,
                      $event.target.value
                    )
                  "
                />
              </div>
              <div v-else>$0.00</div>
            </div>
          </div>
          <div v-if="currentDayOrders.length == 0">
            <h1 class="has-text-centered">No records</h1>
          </div>
        </div>
      </div>
      <!-----end today order list table---- -->
    </div>

    <!--------- DRIVER BALANCES ---------------->
    <modal
      class="driver-balances-modal"
      v-bind:isActive="driverAccountModalStatus"
      v-on:close="closeDriverAccountModal"
    >
      <div slot="body" class="overflow">
        <div class="card">
          <!------- start Table Header ----->
          <header class="card-header">
            <div class="is-grouped"></div>
          </header>
          <!------ end Table Header ----->

          <!-----start today order list table---- -->
          <div class="card-content" v-if="!selectedDriverBalance">
            <div v-if="openDayFile" class="columns is-multiline is-mobile">
              <div class="column has-text-centered">
                <p>
                  <br />
                  DRIVER ACCOUNTS
                  <br />
                  TOTAL OUTSTANDING BALANCE:
                  {{ totalDriverOutstandingBalance | formatAmount }}
                </p>
              </div>
            </div>

            <!-- <----- Start table heading ----->
            <div class="columns table-headings is-size-7">
              <div class="column has-text-centered">DRIVER</div>
              <div class="column has-text-centered">BALANCE</div>
              <div class="column has-text-centered">ADD CASH</div>
              <div class="column has-text-centered">OPEN/CLOSE</div>
            </div>

            <div class="order-details-area">
              <div
                class="columns table-today-orders-rows is-size-7"
                v-for="driver in driverBalances"
                v-bind:key="driver.driverId"
              >
                <div class="column has-text-centered">
                  {{
                    driver.driverName +
                    (driver.DriverLastName ? ` ${driver.DriverLastName}` : "")
                  }}
                </div>
                <div class="column has-text-centered">
                  {{ driver.outstandingBalance | formatAmount }}
                </div>
                <div class="column has-text-centered">
                  <a
                    class="button header-btn is-link is-small"
                    v-on:click="
                      store.isOpen && driver.isOpen && openDriver(driver)
                    "
                    :disabled="!store.isOpen || !driver.isOpen"
                  >
                    <i class="fa fa-plus-circle"></i>ADD CASH
                  </a>
                </div>
                <div class="column has-text-centered">
                  <a
                    class="button header-btn is-link is-small"
                    v-if="!driver.isOpen"
                    v-on:click="store.isOpen && openDriver(driver)"
                    :disabled="!store.isOpen"
                  >
                    <i class="fa fa-plus-circle"></i>OPEN DRIVER
                  </a>
                  <a
                    class="button header-btn is-danger is-small"
                    v-if="driver.isOpen"
                    v-on:click="store.isOpen && startCloseDriver(driver)"
                    :disabled="!store.isOpen && canCloseDriver(driver.driverId)"
                  >
                    <i class="fa fa-minus-circle"></i>CLOSE DRIVER
                  </a>
                </div>
              </div>
            </div>

            <div v-if="driverBalances.length == 0">
              <h1 class="has-text-centered">No records</h1>
            </div>
          </div>
          <!-----end today order list table---- -->

          <div class="card-content" v-if="selectedDriverBalance">
            <div class="columns table-today-orders-rows is-size-7">
              You are about to close out
              {{
                selectedDriverBalance.driverName +
                (selectedDriverBalance.DriverLastName
                  ? ` ${selectedDriverBalance.DriverLastName}`
                  : "")
              }}
            </div>
            <br />
            <div class="columns table-today-orders-rows is-size-7">
              <div class="column has-column-unset is-3 has-text-left">Cash Received</div>
              <div class="column has-column-unset is-3 has-text-right">
                {{ selectedDriverBalance.storeCashReceived | formatAmount }}
              </div>
            </div>
            <div class="columns table-today-orders-rows is-size-7">
              <div class="column has-column-unset is-3 has-text-left">Cash Orders</div>
              <div class="column has-column-unset is-3 has-text-right">
                {{ selectedDriverBalance.cashOrderBalance | formatAmount }}
              </div>
            </div>
            <div class="columns table-today-orders-rows is-size-7">
              <div class="column has-column-unset is-3 has-text-left">Driver Fees</div>
              <div class="column has-column-unset is-3 has-text-right">
                {{ -selectedDriverBalance.driverFeeTotal | formatAmount }}
              </div>
            </div>
            <div class="columns table-today-orders-rows is-size-7">
              <div class="column has-column-unset is-3 has-text-left">Credit Tips</div>
              <div class="column has-column-unset is-3 has-text-right">
                {{ -selectedDriverBalance.creditTipTotal | formatAmount }}
              </div>
            </div>
            <div class="columns table-today-orders-rows is-size-7">
              <div class="column has-column-unset is-3 has-text-left">Cash Returned</div>
              <div class="column has-column-unset is-3 has-text-right">
                {{ selectedDriverBalance.storeCashPaid | formatAmount }}
              </div>
            </div>
            <div class="columns table-today-orders-rows is-size-7">
              <div class="column has-column-unset is-3 has-text-left">Outstanding Balance</div>
              <div class="column has-column-unset is-3 has-text-right">
                {{ selectedDriverBalance.outstandingBalance | formatAmount }}
              </div>
            </div>
            <br />
            <div class="columns table-today-orders-rows is-size-7 has-text-weight-bold">
              {{
                selectedDriverBalance.outstandingBalance > 0
                  ? "Collect "
                  : "Pay "
              }}
              {{ selectedDriverBalance.outstandingBalance | formatAmount }}
              {{
                selectedDriverBalance.outstandingBalance > 0 ? " from " : " to "
              }}
              {{
                selectedDriverBalance.driverName +
                (selectedDriverBalance.DriverLastName
                  ? ` ${selectedDriverBalance.DriverLastName}`
                  : "")
              }}
            </div>
            <br />
            <div>
              <a
                class="button is-danger header-btn has-text-weight-medium"
                v-on:click="store.isOpen && closeDriver(selectedDriverBalance)"
                :disabled="!store.isOpen"
              >
                <i class="fa fa-times-circle"></i> Close Driver
              </a>

              <a
                class="button is-link header-btn has-text-weight-medium"
                v-on:click="store.isOpen && cancelCloseDriver()"
                :disabled="!store.isOpen"
              >
                <i class="fa fa-times-circle"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
  
<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";

var modal = require("../modal.vue").default;
export default {
  components: {
    modal,
    Datepicker,
  },
  data: function () {
    return {
      minLength: 0,
      store_id: this.$store.state.user.store_id,
      base_url: this.$store.state.base_url,
      hub_url: this.$store.state.hub_url,
      isStoreOpen: true,
      dateFormat: "MM/dd/yyyy",
      api_url: this.$store.state.api_url,
      totalDriverOutstandingBalance: 0,
      driverAccountModalStatus: false,
      selectedDriverBalance: null,
      expandSummaryPanel: false,
    };
  },
  filters: {},
  computed: {
    selectedOrders: function () {
      return this.$store.getters["orders/selectedOrders"];
    },
    hasDriverAccounts() {
      return this.$store.state.settings.driverAccounts;
    },
    openDayFile() {
      return this.$store.getters["orders/getOpenDayFile"];
    },
    driverBalances() {
      this.totalDriverOutstandingBalance = 0;
      this.$store.getters["drivers/getDriverBalances"].forEach(
        (v) => (this.totalDriverOutstandingBalance += v.outstandingBalance)
      );

      return this.$store.getters["drivers/getDriverBalances"];
    },
    assignedRoute: function () {
      if (this.selectedDriver) {
        return this.$store.getters["orders/assignedOrdersByDriverId"](
          this.selectedDriver.driver_id
        );
      }
    },
    openDrivers() {
      return this.$store.state.orders.openDrivers;
    },
    selectedDriver() {
      return this.$store.getters["drivers/selectedDriver"];
    },
    drivers: function () {
      return this.$store.getters["drivers/getDrivers"];
    },
    store: function () {
      return this.$store.getters["orders/getStore"];
    },
    showAccounts:function () {
      return this.$store.getters["orders/getStore"]['showAccounts'];
    }, 
    onDutyDrivers: function () {
      return this.$store.getters["drivers/onDutyDrivers"];
    },
    currentDayOrders: function () {
      this.getCurrentDayOrders();

      var storeTransaction =
        this.$store.getters["orders/getStoreCurrentDayTransaction"];
      if (storeTransaction && !storeTransaction.isOpen) {
        this.isStoreOpen = false;
      }

      this.storeSetting = this.$store.state.orders.store;

      var orders = this.$store.getters["orders/getCurrentDayOrders"];

      const parseDate = (dateString) =>
        new Date(parseInt(dateString.match(/\d+/)[0]));

      // Sort the orders by entry_time in descending order
      const sortedOrders = orders.sort((a, b) => {
        const dateA = parseDate(a.entry_time);
        const dateB = parseDate(b.entry_time);
        return dateB - dateA;
      });

      return sortedOrders;
    },
  },
  beforeMount() {
    this.getStore();
    this.getDriverBalances();
    this.$store.dispatch("orders/getOpenDayFile");
  },
  watch: {
    openDrivers() {
      return this.$store.state.orders.openDrivers;
    },
    assignedRoute: function () {
      if (this.selectedDriver) {
        return this.$store.getters["orders/assignedOrdersByDriverId"](
          this.selectedDriver.driver_id
        );
      }
    },
    selectedDriver() {
      return this.$store.getters["drivers/selectedDriver"];
    },
    onDutyDrivers: function () {
      return this.$store.getters["drivers/onDutyDrivers"];
    },
  },
  methods: {
    toggleOrderSelection: function (order) {
      this.$store.dispatch("orders/toggleOrderSelection", order);
    },
    canCloseDriver: function (id) {
      var canClose = true;
      for (var driver of this.drivers) {
        if (driver.driver_id === id) {
          if (driver.time_driver_left != null) canClose = false;
          if (driver.route !== "") canClose = false;
        }
      }

      return canClose;
    },
    formatDateTime: (d) => timeFunctions.formatDateTime(d, true),
    getDriverBalances() {
      this.$store.dispatch("drivers/getDriverBalances");
    },
    isValidNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char) || /^\.+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    input(event) {
      if (event.target.value < this.minLength) {
        alert("abc");
      }
    },
    //set panel
    setPanel: function (panel) {
      this.$store.dispatch("app/setPanel", panel);
    },
    // get CurrentDay Orders
    getCurrentDayOrders: function () {
      var storeId = this.$store.getters["getStoreID"];
      var par = {
        driverId: this.selectedDriver?.driver_id,
        storeId: storeId,
      };
      this.$store.dispatch("orders/getCurrentDayOrders", par);
    },
    getStore: function () {
      this.$store.dispatch("orders/getStore");
    },
    // Add / update tip of order
    checkSpecialKeys: function (e, orderId, tip) {
      var payload = {
        id: parseInt(orderId),
        storeId: this.$store.getters["getStoreID"],
        tip: parseFloat(tip),
      };
      this.$store.dispatch("orders/editOrderTip", payload);
      return true;
    },
    // open store dial pad
    storeDialpad: function () {
      this.setPanel("storeDialPad");
    },
    checkForOpenPickupOrders: function() {
      this.$http.get(this.api_url + "api/v1/stores/GetStoreOpenPickupOrders/" + this.$store.getters["getStoreID"])
        .then(
        (res) => {
          if(res.body.length > 0){
            this.$store.dispatch("app/togglePromptModal", "OPEN_PICKUP_ORDERS");
          }else{
            this.closeStore();
          }
        },
        (res) => {
          console.log("failed");
        }
      );
    },
    //Update store transaction on close day button
    closeStore: function (store) {
      if (store) {
        this.$store = store;
      }
      if (!this.$store.state.app.showPromptModal) {
        this.$store.dispatch("app/togglePromptModal", "CLOSE_DAY");
        return;
      } else {
        this.$store.dispatch("app/togglePromptModal", "CLOSE_DAY");
      }

      var par = {
        transactionType: "CloseDay",
        amount: 0,
      };

      this.$store.dispatch("orders/closeStore", par);
    },
    getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
    getShortOrderNumber: (orderNumber) =>
      orderFunctions.getShortOrderNumber(orderNumber),
    getSourceLogo: function (source) {
      if (!source || source.toLowerCase().trim().includes("ontime")) {
        return "/img/orders/Ontimeminilogo.png";
      } else if (source.toLowerCase().trim().includes("uber")) {
        return "/img/orders/Uberats.png";
      } else if (source.toLowerCase().trim().includes("doordash")) {
        return "/img/orders/doordash-logo2.png";
      } else if (source.toLowerCase().trim().includes("grubhub")) {
        return "/img/orders/grubhub2png.png";
      } else if (source.toLowerCase().trim().includes("square")) {
        return "/img/orders/square2.png";
      } else if (source.toLowerCase().trim().includes("flipdish")) {
        return "/img/orders/flip2.png";
      }
      return false;
    },
    openDriver: function (driver) {
      this.$store.dispatch("drivers/setDriverBalanceFile", driver);
      this.setPanel("driverDialPad");
    },
    startCloseDriver: function (driver) {
      this.selectedDriverBalance = driver;
    },
    cancelCloseDriver: function () {
      this.selectedDriverBalance = null;
    },
    closeDriver: function (driver) {
      this.selectedDriverBalance = null;
      this.$store.dispatch("drivers/setDriverBalanceFile", driver);
      this.$store.dispatch("app/togglePromptModal", "CLOSE_DRIVER");
    },
    getShortName: (name, limitFirstName) =>
      orderFunctions.getShortName(name, limitFirstName),
    getDriverShortName: (fname, lname, limitFirstName) =>
      orderFunctions.getDriverShortName(fname, lname, limitFirstName),
    openDriverAccountModal: function () {
      this.getDriverBalances();
      this.driverAccountModalStatus = true;
    },
    closeDriverAccountModal: function () {
      this.driverAccountModalStatus = false;
    },
  },
};
</script>
  
<style scoped>
.button {
  margin-top: 4px;
  margin-right: auto;
}
.order-details-area {
  padding-top: 5px;
  overflow-y: auto;
  padding-bottom: 12px;
}
.top-text {
  margin-left: 10px;
  margin-top: 6px;
  display: inline-flex;
}

input {
  text-align: center;
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.has-column-unset {
  display: unset;
  align-items: unset;
  justify-content: unset;
}

.middlePanel .card {
  overflow: hidden;
}

</style>
 